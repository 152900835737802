<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Choose the correct description for the various points on the phase change diagram. (G and F
        should be answered as if energy is increasing.)
      </p>
      <p class="mb-5">
        <v-img style="max-width: 400px" src="/img/assignments/energyAddedGraph.png" />
      </p>

      <p class="mb-2">
        <v-select
          v-model="inputs.input1"
          style="max-width: 300px"
          :items="items1Shuffled"
          item-text="text"
          item-value="value"
          label="A"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <v-select
          v-model="inputs.input2"
          style="max-width: 300px"
          :items="items1Shuffled"
          item-text="text"
          item-value="value"
          label="B"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <v-select
          v-model="inputs.input3"
          style="max-width: 300px"
          :items="items1Shuffled"
          item-text="text"
          item-value="value"
          label="C"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <v-select
          v-model="inputs.input4"
          style="max-width: 300px"
          :items="items1Shuffled"
          item-text="text"
          item-value="value"
          label="D"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <v-select
          v-model="inputs.input5"
          style="max-width: 300px"
          :items="items1Shuffled"
          item-text="text"
          item-value="value"
          label="E"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <v-select
          v-model="inputs.input6"
          style="max-width: 300px"
          :items="items2Shuffled"
          item-text="text"
          item-value="value"
          label="F"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <v-select
          v-model="inputs.input7"
          style="max-width: 300px"
          :items="items2Shuffled"
          item-text="text"
          item-value="value"
          label="G"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI1LDA1S1Q4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
      items1: [
        {text: 'Gas', value: 'gas'},
        {text: 'Gas & liquid', value: 'gasLiquid'},
        {text: 'Liquid', value: 'liquid'},
        {text: 'Solid', value: 'solid'},
        {text: 'Liquid & solid', value: 'liquidSolid'},
      ],
      items2: [
        {text: 'Boiling point', value: 'boiling'},
        {text: 'Condensation point', value: 'condensation'},
        {text: 'Freezing point', value: 'freezing'},
        {text: 'Melting point', value: 'melting'},
        {text: 'Sublimation point', value: 'sublimation'},
        {text: 'Triple point', value: 'triple'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    items1Shuffled() {
      return seededShuffle(this.items1, this.seed);
    },
    items2Shuffled() {
      return seededShuffle(this.items2, this.seed);
    },
  },
};
</script>
<style scoped></style>
